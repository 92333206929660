import { themeVars } from '../theme.css';

import { useEffect, useRef, useState } from 'react';

import { legacyTheme, styled } from '@prose-ui/legacy';
import { Slot } from '@radix-ui/react-slot';
import FocusTrap from 'focus-trap-react';
import type { RequireAtLeastOne } from 'type-fest';

import ModalPortal from 'Components/ModalPortal';

import { ReactComponent as Cross } from 'assets/images/cross_icon.svg';

const ModalBackdrop = styled.div<{ isFullWidth: boolean; zIndexValue?: number }>`
  /*
   * The highest z-index known at that time is the checkout ticket at 1500 (not taking into 999999 index instances)
   * Would be great to clean this number once we have a proper z-index strategy
   */
  z-index: ${({ zIndexValue }) => zIndexValue};
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  padding: ${({ isFullWidth }) => (isFullWidth ? 0 : legacyTheme.spacing.s24)};

  background-color: rgba(40, 51, 51, 0.6);
`;

const ModalBody = styled.div<{ isFullWidth: boolean }>`
  position: relative;

  overflow: auto;

  max-width: ${({ isFullWidth }) => (isFullWidth ? 'none' : '360px')};
  max-height: calc(100vh - ${legacyTheme.spacing.s56});
  /**
   * Fallback for dvh:
   * the dynamic unit is supported on modern mobiles only
   * and a small percentage of our users are still on old devices
   */
  @supports (max-height: 100dvh) {
    max-height: calc(100dvh - ${legacyTheme.spacing.s56});
  }
  padding: ${legacyTheme.spacing.s24};
  padding-top: ${legacyTheme.spacing.s72};

  background-color: ${themeVars.colors.neutral[100]};

  ${legacyTheme.breakpoints.up('sm')} {
    max-width: ${({ isFullWidth }) => (isFullWidth ? 'none' : '650px')};
    max-height: calc(100vh - 56px);
  }
`;

const CloseModalButton = styled.button`
  cursor: pointer;

  position: relative;

  width: 14px;
  height: 14px;

  padding: 0;
  margin: 0 ${legacyTheme.spacing.s24} ${legacyTheme.spacing.s12} auto;
  appearance: none;
  background: none;
  border: none;

  ${legacyTheme.breakpoints.up('sm')} {
    margin-bottom: ${legacyTheme.spacing.s56};
  }
`;

type ModalEventsHook = {
  isOpen: boolean;
  modalOpenTime?: number;
  onModalClose?: () => void;
  onModalOpen?: () => void;
  onModalOpenAfterTime?: () => void;
};

/**
 * Hook to trigger actions on specific event:
 * - onModalOpen: event when modal has been opened
 * - onModalOpenAfterTime: event when modal has been opened after wished time
 */
const useModalEvents = ({
  isOpen,
  modalOpenTime = 2000,
  onModalClose,
  onModalOpen,
  onModalOpenAfterTime,
}: ModalEventsHook) => {
  const [hasCompletedOpenedTime, setHasCompletedOpenedTime] = useState(false);
  let timeout: ReturnType<typeof setTimeout> | null = null;
  const hasBeenOpened = useRef(false);
  // Event when modal has been opened
  useEffect(() => {
    if (isOpen && onModalOpen) {
      onModalOpen();
      hasBeenOpened.current = true;
    }
  }, [isOpen]);

  // Event when modal has been closed
  useEffect(() => {
    if (!isOpen && onModalClose && hasBeenOpened.current) {
      onModalClose();
    }
  }, [isOpen]);

  // Event with timer
  useEffect(() => {
    // When modal closed
    if (!isOpen && onModalOpenAfterTime) {
      // Reset timer
      setHasCompletedOpenedTime(false);
    }
    if (!isOpen && timeout) {
      clearTimeout(timeout);
    }

    // When modal opened
    if (isOpen && onModalOpenAfterTime && !hasCompletedOpenedTime) {
      timeout = setTimeout(() => {
        setHasCompletedOpenedTime(true);
      }, modalOpenTime);
    }
    if (isOpen && hasCompletedOpenedTime && onModalOpenAfterTime) {
      onModalOpenAfterTime();
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isOpen, hasCompletedOpenedTime, timeout]);
};

type ModalTimer = {
  modalOpenTime?: number;
  onModalOpenAfterTime: () => void;
};

type ModalEvents = {
  onModalClose: () => void;
  onModalOpen: () => void;
  timer: ModalTimer;
};

export type ModalProps = {
  className?: string;
  dataFrom?: string;
  dataClick?: string;
  children?: React.ReactNode;
  isOpen: boolean;
  isFocusTrapActive?: boolean;
  onClose: () => void;
  dataTestId?: string;
  hideClose?: boolean;
  isFullWidth?: boolean;
  asChild?: boolean;
  events?: RequireAtLeastOne<ModalEvents>;
  zIndexValue?: number;
};

const Modal = ({
  className,
  isOpen,
  onClose,
  children,
  dataFrom = 'modal',
  dataClick = 'modal-close',
  dataTestId = 'modal-closer',
  isFocusTrapActive = true,
  hideClose = false,
  isFullWidth = false,
  asChild = false,
  events,
  zIndexValue = 1501,
}: ModalProps) => {
  useModalEvents({
    isOpen,
    modalOpenTime: events?.timer?.modalOpenTime,
    onModalClose: events?.onModalClose,
    onModalOpen: events?.onModalOpen,
    onModalOpenAfterTime: events?.timer?.onModalOpenAfterTime,
  });
  const ChildrenWrapper = asChild ? Slot : ModalBody;

  return (
    <ModalPortal isOpen={isOpen}>
      <FocusTrap active={isFocusTrapActive}>
        <ModalBackdrop
          data-testid="modal-backdrop"
          isFullWidth={isFullWidth}
          onClick={(e) => {
            onClose();
            e.stopPropagation();
          }}
          onKeyDown={(e) => {
            if (e.which === 27 || e.keyCode === 27 || e.code === 'Escape') {
              onClose();
            }
          }}
          role="dialog"
          zIndexValue={zIndexValue}
        >
          {!hideClose && (
            <CloseModalButton
              aria-label="close"
              data-click={dataClick}
              data-from={dataFrom}
              data-testid={dataTestId}
              id="modal-close-button"
              onClick={onClose}
              type="button"
            >
              <Cross height="14" width="14" />
            </CloseModalButton>
          )}

          <ChildrenWrapper
            aria-describedby="modal-description modal-conditions"
            aria-labelledby="modal-title"
            className={className}
            isFullWidth={isFullWidth}
            onClick={(e) => {
              // This is necessary or clicking on the modal will close it
              e.stopPropagation();
            }}
            role="dialog"
            tabIndex={0}
          >
            {children}
          </ChildrenWrapper>
        </ModalBackdrop>
      </FocusTrap>
    </ModalPortal>
  );
};

export default Modal;
