import { buttonRecipe, type ButtonVariants } from './button.css';

import type React from 'react';

import { Slot } from '@radix-ui/react-slot';

export type ButtonProps<AsType extends React.ElementType = React.ElementType> = {
  children: React.ReactNode;
  as?: AsType;
  asChild?: boolean;
} & ButtonVariants &
  React.ComponentPropsWithoutRef<AsType>;

const defaultElement = 'button';
const Button = <AsType extends React.ElementType = typeof defaultElement>({
  asChild = false,
  as,
  variant,
  size = 'fit',
  mode = 'light',
  padded,
  ...props
}: ButtonProps<AsType>) => {
  const Comp = asChild ? Slot : as ?? defaultElement;

  return <Comp className={buttonRecipe({ variant, size, mode, padded })} {...props} />;
};

export default Button;
